import React, { useState, useEffect } from 'react';
import { Button, CssBaseline, TextField, Link, Grid, MenuItem, Box, Container, Typography, InputAdornment, IconButton, Modal, useMediaQuery } from '@mui/material';
import { Visibility, VisibilityOff, Close } from '@mui/icons-material';
import { Navigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from '../axiosApi';
import NavBar from '../AuthenticateLayout/Dashboard/UsersDashboard/NavBar/navBar';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import InchargeRegAvatar from '../../../assets/inchargeRegAvatar.jpg';


const defaultTheme = createTheme();

export default function InchargeRegisterForm() {
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const initialFormData = {
    name: '',
    email: '',
    phone: '',
    gender: '',
    city: '',
    district: '',
    state: 'Andhra Pradesh',
    pincode: '',
    school_id: '',
    password: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [registerError, setRegisterError] = useState('');
  const [requiredFieldsError, setRequiredFieldsError] = useState({
    name: '',
    email: '',
    phone: '',
    gender: '',
    city: '',
    district: '',
    pincode: '',
    school_id: '',
    password: ''
  });
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showRoleModal, setShowRoleModal] = useState(false);


  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    setRequiredFieldsError({
      ...requiredFieldsError,
      [name]: '',
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSchoolChange = (e) => {
    const { value } = e.target;
    const selectedSchool = options.find(option => option.id === value);
   
    if (selectedSchool) {
      setFormData({
        ...formData,
        school_id: selectedSchool.id
      });
      setRequiredFieldsError({
        ...requiredFieldsError,
        school_id: '',
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${backendURL}/school`);
        setOptions(response.data);
      } catch (error) {
        console.error('Error fetching options:', error);
        if (error.response && error.response.status === 429) {
          const delay = Math.pow(2, 3) * 1000; 
          console.log(`Rate limited. Retrying in ${delay / 1000} seconds...`);
          await new Promise(resolve => setTimeout(resolve, delay));
          fetchData(); 
        }
      }
    };
  
    fetchData(); 
  }, [backendURL]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const requiredFields = ['name', 'email', 'phone', 'gender' , 'city', 'district', 'pincode', 'school_id', 'password'];
    const newRequiredFieldsError = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    requiredFields.forEach(field => {
      if (!formData[field]) {
        newRequiredFieldsError[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required.`;
      }
    });

    if (formData['email'] && !emailRegex.test(formData['email'])) {
      newRequiredFieldsError['email'] = 'Invalid email format.';
    }

    if (Object.keys(newRequiredFieldsError).length > 0) {
      setRequiredFieldsError(newRequiredFieldsError);
      setLoading(false); 
      return;
    }
    const formDataWithRegisterStudent = { ...formData, register_as_student: false };

    try {
        const response = await axios.post(`${backendURL}/register`, formDataWithRegisterStudent);
  
        
        const matrix_user = {
            id: response.data.user.id,
            name: response.data.user.name,
            email: response.data.user.email,
            role: response.data.user.role,
          };
        if(matrix_user.role===6){
          setShowRoleModal(true);
          return;
        }
        localStorage.setItem('matrix_user', JSON.stringify(matrix_user));
        localStorage.setItem('matrix_token', response.data.token);
        setRedirectToReferrer(true);
    } catch (error) {
       console.error('Registration failed:', error);
       if (error.response && error.response.data && error.response.data.error.email) {
        setRegisterError(error.response.data.error.email);
      } else {
        setRegisterError('Registration failed. Please try again.');
      }
     } finally {
      setLoading(false); 
    }
  };

  if (redirectToReferrer) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <>
      <NavBar />
      <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <div style={{display: 'flex', alignItems: 'center'}}> 
         
            <Box
              sx={{
                marginTop: 12,
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: 'space-between',
                backgroundColor: 'white',
                boxShadow: '0 3px 15px 0 rgba(0, 0, 0, .16)',
                padding: '1.5rem',
                borderRadius: '25px'
              }}
            >
               
                <Box sx={{ width: { xs: '100%', md: '48%' }, mb: { xs: 3, md: 0 } }}>
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
                    <AddReactionIcon  style={{fontSize: '2rem', color: '#ac2cc4'}}/>
                    <Typography component="h1" variant="h5">
                      Staff Registration Form
                    </Typography>
                  </div>
                  {formData.error && <div>{formData.error}</div>}
                  <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                      {Object.entries(formData).map(([key, value]) => (
                        <Grid item xs={12} md={6} key={key}>
                          {key === 'gender' ? (
                            <React.Fragment>
                              <TextField
                                required
                                fullWidth
                                select
                                name={key}
                                label={key.charAt(0).toUpperCase() + key.slice(1)}
                                value={value}
                                onChange={handleChange}
                                InputLabelProps={{
                                  shrink: {value} ? true : false,
                                }}
                              >
                                {['Male', 'Female', 'Other'].map((option) => (
                                  <MenuItem key={option} value={option}>
                                    {option}
                                  </MenuItem>
                                ))}
                              </TextField>
                              {requiredFieldsError[key] && <Typography variant="body2" color="error">{requiredFieldsError[key]}</Typography>}
                            </React.Fragment>
                            ) : key === 'school_id' ? (
                              <React.Fragment>
                                <TextField
                                  required
                                  fullWidth
                                  select
                                  name={key}
                                  label= "School"
                                  value={value}
                                  onChange={handleSchoolChange}
                                  InputLabelProps={{
                                    shrink: {value} ? true : false,
                                  }}
                                >
                                  {options.map(option => (
                                    <MenuItem key={option.id} value={option.id}>
                                      {option.school_name}
                                    </MenuItem>
                                  ))}
                                </TextField>
                                {requiredFieldsError[key] && <Typography variant="body2" color="error">{requiredFieldsError[key]}</Typography>}
                              </React.Fragment>
                            
                          ) : (
                            <React.Fragment>
                              <TextField
                                required
                                fullWidth
                                name={key}
                                label={key.charAt(0).toUpperCase() + key.slice(1)}
                                type={
                                  key === 'password'
                                    ? showPassword
                                      ? 'text'
                                      : 'password'
                                    : key === 'phone' || key === 'pincode'
                                    ? 'number'
                                    : 'text'
                                }
                                value={value}
                                onChange={handleChange}
                                InputLabelProps={{
                                  shrink: {value} ? true : false,
                                }}
                                inputProps={{
                                  maxLength: key === 'phone' ? 10 : key === 'pincode' ? 8 : undefined,
                                  onInput: (e) => {
                                    if (key === 'phone') {
                                      e.target.value = e.target.value.slice(0, 10);
                                    }
                                  }
                                }}
                                disabled={key === 'state'}
                                InputProps={
                                  key === 'password'
                                    ? {
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={handleClickShowPassword}
                                              edge="end"
                                            >
                                              {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }
                                    : null
                                }
                              />
                            {requiredFieldsError[key] && <Typography variant="body2" color="error">{requiredFieldsError[key]}</Typography>} 
                            </React.Fragment>
                            
                        )}
                        </Grid>
                      ))}
                    </Grid>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      disabled={loading} 
                      style={{backgroundColor: '#ac2cc4'}}
                    >
                      Sign Up
                    </Button>
                    {registerError && <Typography variant="body2" color="error">{registerError}</Typography>}
                    <Grid container justifyContent="flex-end">
                      <Grid item>
                      <Link
                            component={RouterLink}
                            variant="contained"
                            color="primary"
                            to='/login'
                        >
                            Already have an account? Login
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                  </Box>
                <Box sx={{ width: { xs: '100%', md: '48%' }, textAlign: 'center' }}>
                  <img src={InchargeRegAvatar} alt="incharge-registration-avatar" style={{ width: '100%', height: 'auto' }} />
                </Box>
              
            
            
          </Box>
          
        </div>
        
      </Container>
      <Modal
        open={showRoleModal}
        onClose={() => setShowRoleModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: isSmallScreen ? '90%' : 700,
          bgcolor: 'background.paper',
          borderRadius: '25px',
          boxShadow: 24,
          p: 4,
        }}>
          <IconButton
            aria-label="close"
            onClick={() => setShowRoleModal(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Information
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Thank you for showing your interest in becoming MMO Exam Coordinator.<br/>
            You will receive a call from our team within 48 hrs for the next step.
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Thanking You,
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            The Matrix Team
          </Typography>
        </Box>
      </Modal>
      </ThemeProvider>
    </>
  );
}
