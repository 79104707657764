import React from 'react'
import Olympiads from '../../Olympiads/olympiads';
import NavBar from './NavBar/navBar';
import Footer from '../../Footer/footer';

export default function UserOlympiads() {
  return (
    <>
        <NavBar />
        <Olympiads />
        <Footer />
    </>
    
  )
}
