import React, { useState, useEffect } from 'react';
import axios from '../../axiosApi';
import { Table, TableBody, TableCell, TableHead, TableRow, Container, Box, tableCellClasses, Button} from '@mui/material';
import { useParams } from 'react-router-dom';
import Sidenav from '../Dashboard/AdminDashboard/sidenav/sidenav';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Footer from '../Footer/footer';

export default function SingleSchool() {
    const [singleSchoolData, setSingleSchoolData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedSchool, setEditedSchool] = useState({});
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    
    const { id } = useParams(); 

    useEffect(() => {
        const fetchSingleSchoolData = async () => {
            try {
                const token = localStorage.getItem('matrix_token'); 
                const headers = {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                };
                const response = await axios.get(`${backendURL}/admin/school/${id}`, { headers })
                setSingleSchoolData(response.data);
                setEditedSchool(response.data);

            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchSingleSchoolData();
    }, [id, backendURL]);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleUpdate = async () => {
        try {
            const token = localStorage.getItem('matrix_token'); 
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };

            const allFields = {
                id: editedSchool.id,
                school_name: editedSchool.school_name,
                school_email: editedSchool.school_email,
                school_phone: editedSchool.school_phone,
                school_city: editedSchool.school_city,
                school_district: editedSchool.school_district,
                school_state: editedSchool.school_state,
                school_landmark: editedSchool.school_landmark,
                school_unique_code: editedSchool.school_unique_code

            };

            await axios.put(`${backendURL}/admin/school/update/${id}`, allFields, { headers });
            setIsEditing(false);
            setSingleSchoolData(editedSchool); 
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };

    const handleChange = (e) => {
        setEditedSchool({
            ...editedSchool,
            [e.target.name]: e.target.value
        });
    };
    
  return (
    <>
    <Sidenav />
    <Box sx={{ display: 'flex' }}>
        
        <Container sx={{ flexGrow: 1, justifyContent: 'center'}}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '5rem',}}>
                {singleSchoolData && (
                    <Table style={{ width: '45%', border: '1px solid #ccc',  marginBottom: '1rem',  boxShadow: '0 3px 15px 0 rgba(0, 0, 0, .16)'}}>
                    <TableHead style={{border: '1px solid #ccc'}}>
                        <StyledTableRow>
                        <StyledTableCell colSpan={2} style={{ textAlign: 'center' }}>School Details</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow>
                        <StyledTableCell className='tableCell'><strong>School Id</strong></StyledTableCell>
                        <StyledTableCell>{isEditing ? <input type="text" name="id" value={editedSchool.id} onChange={handleChange} /> : singleSchoolData.id}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                        <StyledTableCell className='tableCell'><strong>School Name</strong></StyledTableCell>
                        <StyledTableCell>{isEditing ? <input type="text" name="school_name" value={editedSchool.school_name} onChange={handleChange} /> : singleSchoolData.school_name}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                        <StyledTableCell className='tableCell'><strong>School Email</strong></StyledTableCell>
                        <StyledTableCell>{isEditing ? <input type="text" name="school_email" value={editedSchool.school_email} onChange={handleChange} /> : singleSchoolData.school_email}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                        <StyledTableCell className='tableCell'><strong>School Phone</strong></StyledTableCell>
                        <StyledTableCell>{isEditing ? <input type="text" name="school_phone" value={editedSchool.school_phone} onChange={handleChange} /> : singleSchoolData.school_phone}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                        <StyledTableCell className='tableCell'><strong>School City</strong></StyledTableCell>
                        <StyledTableCell>{isEditing ? <input type="text" name="school_city" value={editedSchool.school_city} onChange={handleChange} /> : singleSchoolData.school_city}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                        <StyledTableCell className='tableCell'><strong>School District</strong></StyledTableCell>
                        <StyledTableCell>{isEditing ? <input type="text" name="school_district" value={editedSchool.school_district} onChange={handleChange} /> : singleSchoolData.school_district}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                        <StyledTableCell className='tableCell'><strong>School State</strong></StyledTableCell>
                        <StyledTableCell>{isEditing ? <input type="text" name="school_state" value={editedSchool.school_state} onChange={handleChange} /> : singleSchoolData.school_state}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                        <StyledTableCell className='tableCell'><strong>School Landmark</strong></StyledTableCell>
                        <StyledTableCell>{isEditing ? <input type="text" name="school_state" value={editedSchool.school_landmark} onChange={handleChange} /> : singleSchoolData.school_landmark}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                        <StyledTableCell className='tableCell'><strong>School Unique Code</strong></StyledTableCell>
                        <StyledTableCell>{isEditing ? <input type="text" name="school_unique_code" value={editedSchool.school_unique_code} onChange={handleChange} /> : singleSchoolData.school_unique_code}</StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                    </Table>
                    
                )}
                {isEditing ? (
                
                    <Button
                        fullWidth
                        variant="contained"
                        component={RouterLink}
                        onClick={handleUpdate}
                        style={{backgroundColor: '#ac2cc4', width: '5rem'}}
                        >
                        Update
                    </Button>
                    
                       
                    ) : (
                        <Button
                            fullWidth
                            variant="contained"
                            component={RouterLink}
                            onClick={handleEdit}
                            style={{backgroundColor: '#ac2cc4', width: '5rem'}}
                            >
                            Edit
                        </Button>
                    )}
                
            </Box>
            
        </Container>
        
    </Box>
    <Footer />
    </>
  )
}
