import React, { useState, useEffect } from 'react';
import { Button, CssBaseline, TextField, Grid, Box, Container, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from '../../../../axiosApi';
import SubjectForm from "../../../Olympiads/subjectForm";
import { Navigate } from 'react-router-dom';
import Sidenav from '../sidenav/sidenav';
import ModeIcon from '@mui/icons-material/Mode';
import Footer from '../../../Footer/footer';

const defaultTheme = createTheme();

const NewOlympiad = () => {
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const initialFormData = {
    name: '',
    description: '',
    start_date: '',
    end_date: '',
    registration_deadline: '',
    start_date_2: '',
    end_date_2: '',
    registration_deadline_2: ''
  };

  const [formData, setFormData] = useState(initialFormData);
  const [subjectFormData, setSubjectFormData] = useState([]);
  const [matrixToken, setMatrixToken] = useState(null);
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [requiredFieldsError, setRequiredFieldsError] = useState({
    name: '',
    description: '',
    start_date: '',
    end_date: '',
    registration_deadline: '',
    start_date_2: '',
    end_date_2: '',
    registration_deadline_2: ''
  });
  const [subjectError, setSubjectError] = useState('');
  const [author_id, setAuthor_id] = useState();
  const [status_1, setStatus_1] = useState(false);
  const [status_2, setStatus_2] = useState(false);


  useEffect(() => {
    const token = localStorage.getItem('matrix_token');
    setMatrixToken(token);
     setAuthor_id(JSON.parse(localStorage.getItem('matrix_user'))?.id);
     
  }, []); 




const handleChange = (e) => {
  const { name, value } = e.target;
  const today = new Date();

  if (name === 'start_date' || name === 'start_date_2') {
    const selectedDate = new Date(value);
    if (selectedDate < today) {
      setFormData({
        ...formData,
        [name]: '', 
      });
      setRequiredFieldsError({
        ...requiredFieldsError,
        [name]: `${name.replace('_', ' ').toUpperCase()} cannot be before today`,
      });
      return;
    }
  }

  if (name === 'end_date' || name === 'end_date_2' || name === 'registration_deadline' || name === 'registration_deadline_2') {
    const startDateKey = name.includes('_2') ? 'start_date_2' : 'start_date';
    const startDate = new Date(formData[startDateKey]);
    const selectedDate = new Date(value);
    
    if ((name.includes('end_date') || name.includes('end_date_2')) && selectedDate < startDate) {
      setFormData({
        ...formData,
        [name]: '', 
      });
      setRequiredFieldsError({
        ...requiredFieldsError,
        [name]: `${name.replace('_', ' ').toUpperCase()} cannot be before ${startDateKey.replace('_', ' ').toUpperCase()}`,
      });
      return;
    }

    if (name.includes('registration_deadline') && selectedDate > startDate) {
      setFormData({
        ...formData,
        [name]: '', 
      });
      setRequiredFieldsError({
        ...requiredFieldsError,
        [name]: `${name.replace('_', ' ').toUpperCase()} cannot be after ${startDateKey.replace('_', ' ').toUpperCase()}`,
      });
      return;
    }
  }

  setFormData({
    ...formData,
    [name]: value
  });
  setRequiredFieldsError({
    ...requiredFieldsError,
    [name]: '',
  });

};


 const handleSubjectFormDataChange = (data) => {
    const updatedFormData = { ...formData };
  
    data.forEach(subject => {
      if (subject.level === 1) {
        setStatus_1(true)
      } else if (subject.level === 2) {
        setStatus_2(true)
      }
    });
  
    const duplicateSubjects = data.filter((subject, index) => {
      return data.findIndex(
        (item) =>
          item.subject === subject.subject &&
          item.level === subject.level &&
          index !== data.indexOf(item)
      ) !== -1;
    });
  
    if (duplicateSubjects.length > 0) {
      setSubjectError("Subjects with the same level cannot be duplicated.");
      return;
    }
  
    setFormData(updatedFormData);
    setSubjectFormData(data);
    setSubjectError('');
  };
  
  


  const handleSubmit = async (status) => {
    const requiredFields = [ 'name', 'description', 'start_date', 'end_date', 'registration_deadline'];
    const newRequiredFieldsError = {};
    
    if (subjectError) {
      return; 
    }

    requiredFields.forEach(field => {
      if (!formData[field]) {
        newRequiredFieldsError[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required.`;
      }
    });

    if (Object.keys(newRequiredFieldsError).length > 0) {
      setRequiredFieldsError(newRequiredFieldsError);
      return;
    }


    if (subjectFormData.length === 0 || subjectFormData.some(subject => Object.values(subject).some(value => value === ''))) {
      setSubjectError("At least one subject should be added.");
      return;
    } else {
      setSubjectError('');
    }

    const olympiadFormData = { 
      ...formData,
      subject: subjectFormData,
      author_id: author_id,
      status: status_1,
      status_2: status_2}; 
     try {
        const headers = {
          'Authorization': `Bearer ${matrixToken}`,
          'Content-Type': 'application/json'
        };

        const response = await axios.post(`${backendURL}/admin/olympiad/create`, olympiadFormData, {headers});
         setRedirectToReferrer(true);
     } catch (error) {
        console.error('Form submission failed:', error);
      }
  };

  if (redirectToReferrer) {
    return <Navigate to="/admin/olympiads" />;
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Sidenav />
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box sx={{ display: 'flex', marginTop: 0}}>
          <Box sx={{
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flexGrow: 1,
            backgroundColor: 'white',
            boxShadow: '0 3px 15px 0 rgba(0, 0, 0, .16)',
            padding: '0rem 2rem',
            margin: '2rem 0rem',
            borderRadius: '25px'
          }}>
            {formData.error && <div>{formData.error}</div>}
            <Box component="form" noValidate onSubmit={(e) => e.preventDefault()} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
                    <ModeIcon  style={{fontSize: '2rem', color: '#ac2cc4'}}/>
                    <Typography variant="h5" align="center" style={{fontWeight: '600'}}>Create Olympiad</Typography>
                  </div>
                </Grid>
                {Object.entries(formData).map(([key, value]) => (
                  <Grid item xs={12} key={key}>
                    <TextField
                      required={key === 'name' || key === 'description' || key === 'start_date' || key === 'end_date' || key === 'registration_deadline'}
                      fullWidth
                      name={key}
                      label={key === 'start_date' ? 'Level1 Start Date' : key === 'end_date' ? 'Level1 End Date' : key === 'registration_deadline' ? 'Level1 Registration Deadline' : key === 'start_date_2' ? 'Level2 Start Date' : key === 'end_date_2' ? 'Level2 End Date' : key === 'registration_deadline_2' ? 'Level2 Registration Deadline' : key.charAt(0).toUpperCase() + key.slice(1)}
                      type={key === 'start_date' || key === 'end_date' || key === 'registration_deadline' || key === 'start_date_2' || key === 'end_date_2' || key === 'registration_deadline_2' ? 'date' : 'text'}
                      value={value}
                      onChange={handleChange}
                      InputLabelProps={key === 'start_date' || key === 'end_date' || key === 'registration_deadline' || key === 'start_date_2' || key === 'end_date_2' || key === 'registration_deadline_2' ? { shrink: true } : {}}
                      inputProps={key === 'start_date' || key === 'end_date' || key === 'registration_deadline' || key === 'start_date_2' || key === 'end_date_2' || key === 'registration_deadline_2' ? { min: new Date().toISOString().split('T')[0] } : {}}
                    />
                    {requiredFieldsError[key] && <Typography variant="body2" color="error">{requiredFieldsError[key]}</Typography>}
                  </Grid>
                ))}
                <SubjectForm onSubjectFormDataChange={handleSubjectFormDataChange} />
                {subjectError && (
                  <Typography variant="body2" color="error">
                    {subjectError}
                  </Typography>
                )}
              </Grid>
              <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => handleSubmit(true)}
                  style={{backgroundColor: '#ac2cc4', width: '7rem'}}
                >
                  Publish
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => handleSubmit(false)}
                  style={{backgroundColor: '#ac2cc4', width: '7rem'}}
                >
                  Save
                </Button>
              </div>
            </Box>
          </Box>
        </Box>
      </Container>
      <Footer />
    </ThemeProvider>
  );
};

export default NewOlympiad;
