import React, { useState, useEffect } from 'react';
import axios from '../../axiosApi';
import { Table, TableHead, TableBody, TableCell, TableRow, Typography, Button, tableCellClasses, Link } from '@mui/material';
import { CSVLink } from 'react-csv';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { parse } from 'papaparse';
import { Link as RouterLink } from 'react-router-dom';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import './olympiad.css';
import SubjectTable from './subjectTable';
import SubjectForm from './subjectForm';


const OlympiadId = ( ) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState('');
  const [csvData, setCsvData] = useState([]);
   const { id } = useParams(); 
  const [isEditing, setIsEditing] = useState(false);
  const role = localStorage.getItem('matrix_user') ? JSON.parse(localStorage.getItem('matrix_user')).role : null;
  const navigate = useNavigate();
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [olympiad, setOlympiad] = useState(null);
  const [editedOlympiad, setEditedOlympiad] = useState({});  
  const [subjectList, setSubjectList] = useState([]);
  const [subjectFormData, setSubjectFormData] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [subjectError, setSubjectError] = useState('');
  const token = localStorage.getItem('matrix_token');
  const [emailMessage, setEmailMessage] = useState("");

 
 

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        
        const headers = {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        };
        
        const response = await axios.get(`${backendURL}/olympiads/${id}`, {headers});
        
        setOlympiad(response.data);
       
        setSubjectList(response.data.data.subjects); 
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id, backendURL, token]); 
  useEffect(() => {
    if (olympiad) {
        setEditedOlympiad({ ...olympiad.data });
    }
  }, [olympiad]);

  useEffect(() => {
    const verifyEmail = async () => {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };
            const response = await axios.get(`${backendURL}/verify-email`, {headers});
            setEmailMessage(response.data.message);
        } catch (error) {
            console.error('Error in fetching verify email data', error);
        }
    };

    if (token) {
        verifyEmail();
    }
}, [token, backendURL]);

  const handleSelectCSV= (e) => {
    const file = e.target.files[0]; 
    if (file) {
      setSelectedFile(file);
      parseCSV(file); 

    } else {
      console.log('No file selected');
    }
  };


const parseCSV = (file) => {
  parse(file, {
      complete: (result) => {
         

          const subjectMap = {};
          subjectList.forEach(subject => {
              subjectMap[subject.subject] = subject.id;
          });
          
          const updatedData = result.data.map(row => {
              const subjects = [];
              Object.keys(row).forEach(key => {
                  if (row[key] === 'Y' && subjectMap.hasOwnProperty(key)) {
                      subjects.push(subjectMap[key]);
                  }
              });
              return { ...row, subjects };
          });
          setCsvData(updatedData);
      },
      header: true,
      skipEmptyLines: true
  });
};




  const handleRemoveFile = () => {
    setSelectedFile(null); 
    setCsvData([]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      console.log('No file selected');
      setMessage('No file selected');
      return;
    }
    try {
      const token = localStorage.getItem('matrix_token');
     
        const headers = {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        };
       
        const formData = csvData.map(row => ({
          name: row.name,
          email: row.email,
          aadhar_number: row.aadhar_number.replace(/-/g, ''),
          phone: row.phone.replace(/-/g, ''),
          father: row.father,
          mother: row.mother,
          class: row.class,
          dob: row.dob,
          city: row.city,
          district: row.district,
          pincode: row.pincode,
          school_unique_code: row.school_unique_code,
          olympiad_id: row.olympiad_id,
          subjects: row.subjects,
          level: 1

      }));
      
      const response = await axios.post(`${backendURL}/incharge-register`, formData, {headers} );
      setMessage(response.data.message);
      setRedirectToReferrer(true);
    } catch (error) {
      console.error('Error uploading file:', error);
      const errorData = error.response?.data?.error;
      if (errorData) {
        const errors = Object.keys(errorData).map((key) => errorData[key].join(', '));
        setMessage(errors);
      } else {
        setMessage(['Error uploading file']);
      }
    }
  };
  if (redirectToReferrer) {
    return <Navigate to={"/incharge/olympiad/your-olympiad"} />;
  }
  
  
  const convertToCSV = () => {
    if (!subjectList.length || !olympiad) return '';
    const data_header = ['name', 'email', 'aadhar_number', 'phone', 'father', 'mother', 'class', 'dob', 'city', 'district', 'pincode', 'school_unique_code', 'olympiad_id'];
    const subject_header = subjectList.filter(subject => subject.level === 1).map(subject => subject.subject);
    const randomSubjectValues = subject_header.map(subject => Math.random() < 0.5 ? 'Y' : '');
    const olympiad_id = olympiad.data.id;
    const header = [data_header, subject_header];
    const rows = ['A.Student', 'student@gmail.com', '3123-4567-8901' , '91234-56780', 'father name', 'mother name', '5' , '30-10-2020', 'vijayawada', 'NTR', '520012', 'brendon.heller', olympiad_id, ...randomSubjectValues]
    const csv = [header, rows].map(row => row.join(',')).join('\n');
    return csv;
  };

  const handleSubjectSelection = (subjectId) => {
    setSelectedSubjects((prevSelected) => {
      if (prevSelected.includes(subjectId)) {
        return prevSelected.filter((id) => id !== subjectId);
      } else {
        return [...prevSelected, subjectId];
      }
    });
  };

  const handleRegister = async () => {
    if (selectedSubjects.length === 0) {
        setError('Please select at least one subject.');
        return;
    }
    try {
        const token = localStorage.getItem('matrix_token');
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
           'Accept' : '/'
        };
        const selectedSubjectData = subjectList.filter((subject) =>
            selectedSubjects.includes(subject.id)
        );
       
        const selectedSubjectIds = selectedSubjectData.map(subject => subject.id);
        const selectedSubjectLevel = selectedSubjectData.map(subject => subject.level)[0];
        
        const formData = {
          olympiad_id: olympiad.data.id,
            subjects: selectedSubjectIds,
            level: selectedSubjectLevel
        };

        
        const response = await axios.post(`${backendURL}/student/olympiad/register`, formData, { headers });
        console.log(response);
        navigate(`/student/olympiad/${id}/registered`);
    } catch (error) {
        console.error('Error registering:', error);
       
    }
};

  



const handleEdit = () => {
  setIsEditing(true);

  setEditedOlympiad({ ...olympiad.data });
};

const handleChange = (e) => {
  setEditedOlympiad({
      ...editedOlympiad,
      [e.target.name]: e.target.value
  });
};

 const handleSubjectFormDataChange = (data) => {
 

  const duplicateSubjects = data.filter((subject, index) => {
    return data.findIndex(
      (item) =>
        item.subject === subject.subject &&
        item.level === subject.level &&
        index !== data.indexOf(item)
    ) !== -1;
  });

  if (duplicateSubjects.length > 0) {
    setSubjectError("Subjects with the same level cannot be duplicated.");
    return;
  }


  setSubjectFormData(data);
  setSubjectError('');

};

 const subjectsdata1 = subjectList.map(sub => ({
  subject: sub.subject,
  level: sub.level,
  subject_fee: sub.subject_fee,
  subject_marks: sub.subject_marks
}))


const subjectsdata2 = subjectFormData.map(sub => ({
  subject: sub.subject,
  level: sub.level,
  subject_fee: sub.subject_fee,
  subject_marks: sub.subject_marks
}))



let subjects;
if (subjectsdata2[0] && subjectsdata2[0].level === '') {
  subjects = subjectsdata1;
} else {
  subjects = subjectsdata1.concat(subjectsdata2);
}



const updatedData = {
  name: editedOlympiad.name,
  description: editedOlympiad.description,
  start_date: editedOlympiad.start_date,
  end_date: editedOlympiad.end_date,
  status: true,
  registration_deadline: editedOlympiad.registration_deadline,
  start_date_2: editedOlympiad.start_date_2,
  end_date_2: editedOlympiad.end_date_2,
  status_2: true,
  registration_deadline_2: editedOlympiad.registration_deadline_2,
  subject: subjects
};


  const handleUpdate = async () => {
    const token = localStorage.getItem('matrix_token');
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    
    try {
      const response = await axios.put(`${backendURL}/admin/olympiad/update/${id}`, updatedData, { headers });
      
      setMessage(response.data.message)
      setIsEditing(false);
      setOlympiad(editedOlympiad);
      window.location.reload();
    } catch (error) {
      console.error('Error updating olympiad:', error);
      setMessage('Error in updating')
    }
  };

  
  

  const handleGetCSV = async () => {
    try {
        const token = localStorage.getItem('matrix_token'); 
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const response = await axios.get(`${backendURL}/incharge/olympiadcsv/${id}`, {
            headers: headers
        });
        

        const data_header = ['name', 'aadhar_number','class','father_name','hall_ticket_no', 'level', 'olympiad_id', 'school_unique_code'];
        const subject_header = subjectList.filter(subject => subject.level === 2).map(subject => subject.subject);
        const randomSubjectValues = subject_header.map(subject => Math.random() < 0.5 ? 'Y' : '');
        const level2_header = [data_header, subject_header];
        const level2_rows = response.data.list.map(data => {
          const aadharNumber = String(data.aadhar_number); 
          const formattedAadharNumber = aadharNumber.slice(0, 4) + '-' + aadharNumber.slice(4, 8) + '-' + aadharNumber.slice(8);
          return {
              name: data.name,
              aadhar_number: formattedAadharNumber,
              class: data.class,
              father_name: data.father,
              hall_ticket_no: data.hall_ticket_no,
              level: 2,
              olympiad_id: data.olympiad_id,
              school_unique_code: data.school_unique_code,
              subjects: randomSubjectValues,
          };  
        });

        
        const level2_csv = [level2_header.join(','), ...level2_rows.map(row => Object.values(row).join(','))].join('\n');
        
        const blob = new Blob([level2_csv], { type: 'text/csv' });

        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'level2_data.csv'); 
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error downloading CSV:', error);
    }
};

const handleUploadLevel2 = async () => {
  if (!selectedFile) {
    console.log('No file selected');
    return;
  }
  try {
    const token = localStorage.getItem('matrix_token');
   
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };
     
      const formData = csvData.map(row => ({
        aadhar_number: row.aadhar_number.replace(/-/g, ''),
        level: row.level,
        olympiad_id: row.olympiad_id,
        subjects: row.subjects,
        school_unique_code: row.school_unique_code,
    }));
    
    const response = await axios.post(`${backendURL}/incharge-registerlevel2`, formData, {headers} );
    setMessage(response.data.message);
    setRedirectToReferrer(true);
  } catch (error) {
    setMessage("Error uploading file");
  }
};
if (redirectToReferrer) {
  return <Navigate to={"/incharge/olympiad/your-olympiad"} />;
}




  return (
    
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      {olympiad && (
        <Table style={{ width: '100%', border: '1px solid #ccc',  boxShadow: '0 5px 20px 0 rgba(0, 0, 0, .16)' }}>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell colSpan={2} style={{ textAlign: 'center'}}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem'}}>
                <MenuBookIcon style={{fontSize: '2rem', color: 'white'}}/>
                 <Typography component="h1" variant="h6" >
                   Olympiad Details
                   </Typography>
                </div>
                 
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell className='tableCell'><strong>Id</strong></StyledTableCell>
              <StyledTableCell>{isEditing ? (<input type="text" name="id" value={editedOlympiad.id} onChange={handleChange} /> ) : ( olympiad?.data?.id )}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell className='tableCell'><strong>Name</strong></StyledTableCell>
              <StyledTableCell>{isEditing ? (<input type="text" name="name" value={editedOlympiad.name} onChange={handleChange} /> ) : ( olympiad?.data?.name )}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell className='tableCell'><strong>Description</strong></StyledTableCell>
              <StyledTableCell>{isEditing ? (<input type="text" name="description" value={editedOlympiad.description} onChange={handleChange} /> ) : ( olympiad?.data?.description)}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell className='tableCell'><strong>Level 1 Start Date</strong></StyledTableCell>
              <StyledTableCell>{isEditing ? (<input type="date" name="start_date" value={editedOlympiad.start_date} onChange={handleChange} /> ) : ( olympiad?.data?.start_date )}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell className='tableCell'><strong>Level 1 End Date</strong></StyledTableCell>
              <StyledTableCell>{isEditing ? (<input type="date" name="end_date" value={editedOlympiad.end_date} onChange={handleChange} /> ) : ( olympiad?.data?.end_date )}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell className='tableCell'><strong>Level 1 Registration Deadline</strong></StyledTableCell>
              <StyledTableCell>{isEditing ? (<input type="date" name="registration_deadline" value={editedOlympiad.registration_deadline} onChange={handleChange} /> ) : ( olympiad?.data?.registration_deadline )}</StyledTableCell>
            </StyledTableRow>
            {(role === 1 || olympiad.data.start_date_2) && (
            <StyledTableRow>
              <StyledTableCell className='tableCell'><strong>Level 2 Start Date</strong></StyledTableCell>
              <StyledTableCell>{isEditing ? (<input type="date" name="start_date_2" value={editedOlympiad.start_date_2} onChange={handleChange} /> ) : ( olympiad?.data?.start_date_2 )}</StyledTableCell>
            </StyledTableRow>
            )}
            {(role === 1 || olympiad.data.end_date_2) && (
            <StyledTableRow>
              <StyledTableCell className='tableCell'><strong>Level 2 End Date</strong></StyledTableCell>
              <StyledTableCell>{isEditing ? (<input type="date" name="end_date_2" value={editedOlympiad.end_date_2} onChange={handleChange} /> ) : ( olympiad?.data?.end_date_2)}</StyledTableCell>
            </StyledTableRow>
            )}
            {(role === 1 || olympiad.data.registration_deadline_2) && (
            <StyledTableRow>
              <StyledTableCell className='tableCell'><strong>Level 2 Registration Deadline</strong></StyledTableCell>
              <StyledTableCell>{isEditing ? (<input type="date" name="registration_deadline_2" value={editedOlympiad.registration_deadline_2} onChange={handleChange} /> ) : ( olympiad?.data?.registration_deadline_2)}</StyledTableCell>
            </StyledTableRow>
            )}
          </TableBody>
        </Table>
      )}
       
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
        
        <SubjectTable olympiad={olympiad} subjectList={subjectList} selectedSubjects={selectedSubjects} handleSubjectSelection={handleSubjectSelection} />
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {subjectError && <p style={{ color: 'red' }}>{subjectError}</p>}
        {role === 5 && (
          <>
            {emailMessage === 'Verification link Send successfully' && <p style={{ color: 'red' }}>You are not verified. Verification link Send successfully to your mail. Please verify mail to continue Register.</p>}
            <Button variant="contained" color="primary" onClick={handleRegister} style={{ marginTop: '1rem', backgroundColor: '#ac2cc4'}}>
              Register
            </Button>
          </>
        )}
        {role === 1 && (
          <>
              {isEditing ? (
                <>
                  <Link
                      component={RouterLink}
                      variant="contained"
                      color="primary"
                      onClick={handleUpdate}
                      style={{backgroundColor: '#ac2cc4', color: 'white', padding: '0.5rem 1rem', textDecoration: 'none', borderRadius: '5px'}}
                  >
                      Update
                  </Link>
                  {message && <p style={{color: 'red'}}>{message}</p>}
                  <SubjectForm onSubjectFormDataChange={handleSubjectFormDataChange} />
                  </>
              ) : (
                  <Button
                      component={RouterLink}
                      variant="contained"
                      color="primary"
                      onClick={handleEdit}
                      style={{backgroundColor: '#ac2cc4', color: 'white', padding: '0.5rem 1rem', textDecoration: 'none', borderRadius: '5px'}}
                  >
                      Edit
                  </Button>
              )}
          </>
      )}
        {role === 2 && olympiad?.allowed === 1 ? (
        <div>
          <input
            type="file"
            accept=".csv"
            style={{ display: 'none' }}
            id="select-csv"
            onChange={handleSelectCSV}
          />
          <label htmlFor="select-csv">
            <Button variant="contained" color="primary" component="span" style={{ marginTop: '1rem', marginRight: '1rem', backgroundColor: '#ac2cc4' }}>
            Select CSV
            </Button>
          </label>
          <Button variant="contained" color="primary" component="span" style={{ marginTop: '1rem', marginRight: '1rem', backgroundColor: '#ac2cc4' }} onClick={handleUpload}>
            Upload CSV
          </Button>
          
          {selectedFile && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>{selectedFile.name}</span>
              <IconButton onClick={handleRemoveFile} aria-label="remove file">
                <DeleteIcon />
              </IconButton>
            </div>
          )}
          <CSVLink
            data={convertToCSV()}
            filename={'sample.csv'}
            className="btn btn-primary"
            target="_blank"
            style={{ marginTop: '1rem' }}
            variant="contained" color="primary"
          >
            <Button variant="contained" color="primary" component="span" style={{ marginTop: '1rem', marginRight: '1rem', backgroundColor: '#ac2cc4' }}>
              Download Sample CSV 
            </Button>
          </CSVLink>
          {message && <p style={{color: 'red'}}>{message}</p>}
        </div>
      ) : role === 2 && olympiad?.allowed === 2 ? (
        <div>
          
          <Button variant="contained" color="primary" component="span" style={{ marginTop: '1rem', marginRight: '1rem', backgroundColor: '#ac2cc4' }} onClick={handleGetCSV}>
            Get CSV
          </Button>
          <input
            type="file"
            accept=".csv"
            style={{ display: 'none' }}
            id="select-csv"
            onChange={handleSelectCSV}
          />
          <label htmlFor="select-csv">
            <Button variant="contained" color="primary" component="span" style={{ marginTop: '1rem', marginRight: '1rem', backgroundColor: '#ac2cc4' }}>
              Select CSV
            </Button>
          </label>
            <Button variant="contained" color="primary" component="span" style={{ marginTop: '1rem', marginRight: '1rem', backgroundColor: '#ac2cc4' }} onClick={handleUploadLevel2}>
              Upload CSV
            </Button>
            {selectedFile && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>{selectedFile.name}</span>
              <IconButton onClick={handleRemoveFile} aria-label="remove file">
                <DeleteIcon />
              </IconButton>
            </div>
          )}
        </div>
       
      ) : null}

      </div>
    </div>
  );
};

export default OlympiadId;
