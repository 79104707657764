import React from 'react';
import NavBar from './NavBar/navBar';
import Profile from '../../profile';
import Footer from '../../Footer/footer';

export default function UserProfile() {
  return (
    <>
        <NavBar />
        <Profile />
        <Footer />
    </>
        
  )
}
