import React from 'react';
import { Container, Box } from '@mui/material';
import Sidenav from '../sidenav/sidenav';
import AllSchools from '../../../Schools/allSchools';
import Footer from '../../../Footer/footer';

const AdminAllSchool = () => {
    return (
        <>
        <Sidenav />
        <Box sx={{ display: 'flex' }}>
            
            <Container sx={{ flexGrow: 1, justifyContent: 'center' }}>
                <Box sx={{ mt: 4 }}>
                    <AllSchools />
                </Box>
            </Container>
            
        </Box>
        <Footer />
        </>
    );
};

export default AdminAllSchool;
