import React, { useState, useEffect } from 'react';
import axios from '../../../../../axiosApi';
import { TableContainer, Table, TableHead, TableBody, TableCell, TableRow, Paper, TablePagination, Button, Container, Box, tableCellClasses, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Sidenav from '../../sidenav/sidenav'; 
import { styled } from '@mui/material/styles';
import PeopleIcon from '@mui/icons-material/People';
import Footer from '../../../../Footer/footer';

const UsersData = () => {
    const [usersData, setUsersData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

    useEffect(() => {
        const fetchUsersData = async () => {
            try {
                const token = localStorage.getItem('matrix_token'); 
                const headers = {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                };
                const response = await axios.get(`${backendURL}/admin/users`, { headers });
                setUsersData(response.data.data);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUsersData();
    }, [backendURL]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column'}}>
            <Sidenav />
            <Box sx={{ display: 'flex',flexDirection: 'column',  marginLeft: '5rem' }}>
                
                <Container sx={{ flexGrow: 1, justifyContent: 'center'}}  maxWidth="md">
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '4rem' }}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
                            <PeopleIcon />
                            <Typography variant="h5" align="center" style={{fontWeight: '600'}}>Users Information</Typography>
                        </div>
                        <TableContainer component={Paper} style={{  border: '1px solid #ccc',  boxShadow: '0 3px 15px 0 rgba(0, 0, 0, .16)'}}>
                            <Table stickyHeader >
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell className='tableCell'>ID</StyledTableCell>
                                        <StyledTableCell className='tableCell'>Name</StyledTableCell>
                                        <StyledTableCell className='tableCell'>Email</StyledTableCell>
                                        <StyledTableCell className='tableCell'>Aadhar Number</StyledTableCell>
                                        <StyledTableCell className='tableCell'>Role</StyledTableCell>
                                        <StyledTableCell className='tableCell'>Action</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {Array.isArray(usersData) && usersData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell className='tableCell'>{user.id}</StyledTableCell>
                                            <StyledTableCell className='tableCell'>{user.name}</StyledTableCell>
                                            <StyledTableCell className='tableCell'>{user.email}</StyledTableCell>
                                            <StyledTableCell className='tableCell'>{user.aadhar_number}</StyledTableCell>
                                            <StyledTableCell className='tableCell'>{user.role}</StyledTableCell>
                                            <StyledTableCell className='tableCell'>
                                                
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    component={RouterLink}
                                                    to={`/admin/users/${user.id}`}
                                                    style={{backgroundColor: '#ac2cc4', width: '5rem'}}
                                                    >
                                                    View
                                                </Button>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={usersData ? usersData.length : 0} 
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                </Container>
                <Footer />
            </Box>
            
        </Box>
    );
};

export default UsersData;
