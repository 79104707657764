import React, { useState } from "react";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import HomeIcon from "@mui/icons-material/Home";
import SchoolIcon from '@mui/icons-material/School';
import BadgeIcon from '@mui/icons-material/Badge';
import GroupIcon from '@mui/icons-material/Group';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import { handleLogout } from './accountButton'; 


export default function MenuList({ open }) {
    const [activeItem, setActiveItem] = useState("Home");
    const [openSubMenu, setOpenSubMenu] = useState(false);
    const [openSchoolSubMenu, setOpenSchoolSubMenu] = useState(false);
    const [openUsersSubMenu, setOpenUsersSubMenu] = useState(false);
   

    const menuList = [
        { icon: <HomeIcon />, name: "Home", path: "/admin/dashboard" },
        { icon: <BadgeIcon />, name: "Olympiads", subMenu: [
            { name: "All Olympiads", path: "/admin/olympiads" },
            { name: "Create Olympiad", path: "/admin/olympiad/create" }
        ] },
        { icon: <SchoolIcon />, name: "School", subMenu: [
            { name: "All Schools", path: "/admin/schools" },
            { name: "Create School", path: "/admin/school/create" }
        ] },
        { icon: <GroupIcon />, name: "Users", subMenu: [
            { name: "All Students", path: "/admin/users" },
            { name: "All Incharges", path: "/admin/incharges" },
            { name: "Pending Incharges", path: "/admin/pendingIncharge" }
        ] },
       
        { icon: <AccountCircleIcon />, name: "Profile",  path: "/admin/profile" },
        { icon: <LogoutIcon />, name: "LogOut" }
    ];
   
    const handleItemClick = (item) => {
        setActiveItem(item.name);

        if (item.name === "Olympiads") {
            setOpenSubMenu(!openSubMenu);
            setOpenSchoolSubMenu(false);
            setOpenUsersSubMenu(false);
        } else if (item.name === "School") {
            setOpenSchoolSubMenu(!openSchoolSubMenu);
            setOpenSubMenu(false);
            setOpenUsersSubMenu(false);
        } else if (item.name === "Users") {
            setOpenUsersSubMenu(!openUsersSubMenu);
            setOpenSubMenu(false);
            setOpenSchoolSubMenu(false);
        } else if (item.name === "LogOut"){
            handleLogout();
        } else {
            setOpenSubMenu(false);
            setOpenSchoolSubMenu(false);
            setOpenUsersSubMenu(false);
        }
    };
    
    
    return (
        <List>
            {menuList.map((item, index) => (
                <React.Fragment key={index}>
                    <ListItem
                        disablePadding
                        sx={{
                            display: "block",
                            color: activeItem === item.name ? '#ab47bc' : "black",
                        }}
                        onClick={() => {handleItemClick(item)}}
                    >

                        <ListItemButton
                            component={Link}
                            to={item.path}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                    color: activeItem === item.name ? '#ab47bc' : "black",
                                }}
                            >
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.name} sx={{ opacity: open ? 1 : 0 }} />
                            {item.subMenu && ((item.name === "Olympiads" && openSubMenu) || (item.name === "School" && openSchoolSubMenu) || (item.name === "Users" && openUsersSubMenu)) ? <ExpandLess /> : null}
                            {item.subMenu && !((item.name === "Olympiads" && openSubMenu) || (item.name === "School" && openSchoolSubMenu) || (item.name === "Users" && openUsersSubMenu)) ? <ExpandMore /> : null}
                        </ListItemButton>
                    </ListItem>
                    {item.subMenu && ((item.name === "Olympiads" && openSubMenu) || (item.name === "School" && openSchoolSubMenu) || (item.name === "Users" && openUsersSubMenu)) && (
                        <Collapse in={(item.name === "Olympiads" && openSubMenu) || (item.name === "School" && openSchoolSubMenu) || (item.name === "Users" && openUsersSubMenu)} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {item.subMenu.map((subItem, subIndex) => (
                                    <ListItem
                                        key={subIndex}
                                        disablePadding
                                        sx={{
                                            display: "block",
                                            color: activeItem === subItem.name ? '#ab47bc' : "black",
                                        }}
                                        onClick={() => setActiveItem(item.name)}
                                    >
                                        <ListItemButton
                                            component={Link}
                                            to={subItem.path}
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? "initial" : "center",
                                                px: 4,
                                            }}
                                        >
                                            <ListItemText primary={subItem.name} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </Collapse>
                    )}
                </React.Fragment>
            ))}
        </List>

    );
}

