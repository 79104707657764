import React from 'react';
import { Container, Box } from '@mui/material';
import Sidenav from '../sidenav/sidenav';
import Olympiads from '../../../Olympiads/olympiads';
import Footer from '../../../Footer/footer';

const AdminAllOlympiads = () => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column'}}>
            <Sidenav />
            <Container sx={{ display: 'flex',  justifyContent: 'center'}} maxWidth="md">
                <Box sx={{ mt: 4 }}>
                    <Olympiads />
                </Box>
            </Container>
            <Footer />
        </Box>
    );
};

export default AdminAllOlympiads;
