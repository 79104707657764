import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import OlympiadButton from './olympiadButton';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import './navBar.css'

const drawerWidth = 240;

const navItems = ['About Us', 'Sample Papers', 'Our Toppers', 'Contact', <OlympiadButton />];



function NavBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const wordPressURL = process.env.REACT_APP_WORDPRESS_URL;

  


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getLink = (item) => {
    switch (item) {
      case 'About Us':
        return `${wordPressURL}/about-us`;
      case 'Sample Papers':
        return `${wordPressURL}/sample-papers`;
      case 'Our Toppers':
        return `${wordPressURL}/our-toppers`;
      case 'Contact':
        return `${wordPressURL}/contact`;
      default:
        return '/';
    }
  };

  const drawer = (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <Link to={wordPressURL}>
          <img src={`${wordPressURL}/wp-content/uploads/2024/04/MAtrix_WEBSITE-25.png`} alt='logo' style={{ width: '200px' }} />
        </Link>
      </Typography>
      <Divider />
      <List>
        {navItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <Link to={typeof item === 'string' ? getLink(item) : null} style={{color: 'black', textDecoration: 'none'}}>
                <ListItemText primary={item} />
              </Link>
              
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
  

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex', marginBottom: '8rem' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1 }} style={{backgroundColor: 'white', padding: '6px 0px'}}>
        <Toolbar>
          {isMobile ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'block' } }}
            >
              <MenuIcon  style={{color: 'black'}}/>
            </IconButton>
          ) : null}
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            <Link to= {wordPressURL}>
              <img src={`${wordPressURL}/wp-content/uploads/2024/04/MAtrix_WEBSITE-25.png`} alt='logo' style={{ height: '74.73px', width: '250px' }} />
            </Link>
          </Typography>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            {navItems.map((item) => (
              <Link to={getLink(item)} key={item}>
                <Button className="nav-buttons"> {item}</Button>
              </Link>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
}

NavBar.propTypes = {
  window: PropTypes.func,
};

export default NavBar;
