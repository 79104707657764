import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../../axiosApi';
import { Table, TableBody, TableHead, Button, TableContainer, Paper, tableCellClasses, TableCell, TableRow, Typography, IconButton, TextField, MenuItem, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import NavBar from '../UsersDashboard/NavBar/navBar';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import GroupsIcon from '@mui/icons-material/Groups';
import Footer from '../../Footer/footer';

export default function InchargeSingleRegOlympiad() {
     const [regOlympiad, setRegOlympiad] = useState([]);
     const [inchargeParticipatesData, setInchargeParticipatesData] = useState([]);
    const [totalAmount, setTotalAmount] = useState('');
    const { id } = useParams(); 
    const [selectedLevel, setSelectedLevel] = useState(1);
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [deleteMessage, setDeleteMessage] = useState("");
    const token = localStorage.getItem('matrix_token');

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

    useEffect(() => {
        const fetchInchargeRegisterOlympiadData = async () => {
            try {
                
                const headers = {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                };

                const response = await axios.get(`${backendURL}/incharge/olympiad/${id}/registered`, {headers});
                setRegOlympiad(response.data.data);
                setInchargeParticipatesData(response.data.data.participatesData);
                setTotalAmount(response.data.data.totalAmount);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchInchargeRegisterOlympiadData();
    }, [id, backendURL, token]); 

    const participateUserData = inchargeParticipatesData.map(participateUser => ({
        studentData: participateUser.participant_user,
        hall_ticket_no: participateUser.hall_ticket_no,
        certificate_url: participateUser.certificate_url,
        certificate_url_2: participateUser.certificate_url_2
    }));
    

    const handleDelete = async (inchargeUserId, inchargeUser) => {
        try {
                const token = localStorage.getItem('matrix_token');
                const headers = {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                };
                if (inchargeUser.isfullPaid !== null) {
                    setDeleteMessage("You can't delete this student because payment is complete.");
                    return;
                }
            const response = await axios.delete(`${backendURL}/incharge/olympiad/${id}/registered/${inchargeUserId}`, {headers});
            console.log(response);
           window.location.reload();

        } catch (error) {
            console.error('failed to delete:', error);
        } 
    };

   

    const handlePayment = async () => {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };
            const orderUrl = `${backendURL}/createOrder/${id}`; 
            const response = await axios.get(orderUrl, { headers });
            const data = response.data;

            const options = {
                key: data.razorpay_key,
                amount: data.amount, 
                currency: data.currency,
                name: data.name,
                description: data.description,
                order_id: data.order_id,
                handler: function (response) {
                 
                    alert(`Order ID: ${response.razorpay_order_id}`);
                    
                    
                    verifyPayment(response);
                },
                prefill: {
                    name: data.name,
                  
                },
                notes: {
                    address: data.description
                },
                theme: {
                    color: '#C933CC'
                }
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        } catch (error) {
            console.error('Error creating order:', error);
            alert('Error creating order. Please try again.');
        }
    };

    const verifyPayment = async (paymentDetails) => {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };
            const verifyUrl = `${backendURL}/verify-payment`;
    
            const response = await axios.post(verifyUrl, paymentDetails, { headers });
    
            if (response.data.status === 'success') {
                alert('Payment verified successfully');
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            } else {
                alert('Payment verification failed');
            }
        } catch (error) {
            console.error('Error verifying payment:', error);
            alert('Error verifying payment. Please try again.');
        }
    };

    return (
        <div>
        <NavBar />
            <div  style={{display: 'flex', flexDirection: 'column', margin: '1rem'}}>
                {regOlympiad && regOlympiad.olympiad && (
                    <>
                        <Table style={{ width: '100%', border: '1px solid #ccc', boxShadow: '0 5px 20px 0 rgba(0, 0, 0, .16)' }}>
                    <TableHead>
                        <StyledTableRow>
                        <StyledTableCell colSpan={2} style={{ textAlign: 'center', fontSize: '20px' }}>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem'}}>
                                <MenuBookIcon style={{fontSize: '2rem', color: 'white'}}/>
                                <Typography component="h1" variant="h6" >
                                    Register Olympiad Details
                                </Typography>
                            </div>
                        </StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow>
                        <StyledTableCell className='tableCell'><strong>Olympiad Id</strong></StyledTableCell>
                        <StyledTableCell>{regOlympiad.olympiad.id}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                        <StyledTableCell className='tableCell'><strong>Name</strong></StyledTableCell>
                        <StyledTableCell>{regOlympiad.olympiad.name}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                        <StyledTableCell className='tableCell'><strong>Description</strong></StyledTableCell>
                        <StyledTableCell>{regOlympiad.olympiad.description}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                        <StyledTableCell className='tableCell'><strong>Start Date</strong></StyledTableCell>
                        <StyledTableCell>{regOlympiad.olympiad.start_date}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                        <StyledTableCell className='tableCell'><strong>End Date</strong></StyledTableCell>
                        <StyledTableCell>{regOlympiad.olympiad.end_date}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                        <StyledTableCell className='tableCell'><strong>Registration Deadline</strong></StyledTableCell>
                        <StyledTableCell>{regOlympiad.olympiad.registration_deadline}</StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                    </Table>
                    <div>
                        <TextField
                            required
                            select
                            name='Level'
                            label='Level'
                            value={selectedLevel}
                            onChange={(event) => setSelectedLevel(event.target.value)}
                            style={{width: '15rem', margin: '2rem 0rem'}}
                        >
                            {[1,2].map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem'}}>
                        <GroupsIcon />
                        <h3>Register Student Details</h3>
                    </div>
                    <TableContainer component={Paper} style={{border: '1px solid #ccc', boxShadow: '0 5px 20px 0 rgba(0, 0, 0, .16)', width: 'auto'}}>
                    <Table stickyHeader >
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell className='tableCell'>ID</StyledTableCell>
                                <StyledTableCell className='tableCell'>Name</StyledTableCell>
                                <StyledTableCell className='tableCell'>Aadhar Number</StyledTableCell>
                                <StyledTableCell className='tableCell'>Hall Ticket Number</StyledTableCell>
                                <StyledTableCell className='tableCell'>Certificate</StyledTableCell>
                                <StyledTableCell className='tableCell'>Rank Certificate</StyledTableCell>
                                <StyledTableCell className='tableCell'>Class</StyledTableCell>
                                <StyledTableCell className='tableCell'>Total Amount</StyledTableCell>
                                <StyledTableCell className='tableCell'>Action</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {Array.isArray(participateUserData) && participateUserData.map((user, index) => {   
                                const inchargeUser = inchargeParticipatesData[index]; 
                                if (inchargeUser.level === selectedLevel) {
                                    return (
                                        <>
                                        <StyledTableRow key={user.studentData.id}>
                                            <StyledTableCell className='tableCell'>{user.studentData.id}</StyledTableCell>
                                            <StyledTableCell className='tableCell'>{user.studentData.name}</StyledTableCell>
                                            <StyledTableCell className='tableCell'>{user.studentData.aadhar_number}</StyledTableCell>
                                            <StyledTableCell className='tableCell'>{user.hall_ticket_no}</StyledTableCell>
                                            <StyledTableCell className='tableCell'>
                                                {user.certificate_url !== null ? (
                                                    <Link
                                                        component={RouterLink}
                                                        to={user.certificate_url}
                                                        variant="contained"
                                                        target="_blank"
                                                        style={{backgroundColor: '#1b0080', color: 'white', padding: '8px', textDecorationColor: 'white'}}
                                                    >
                                                        Download Certificate
                                                    </Link>
                                                ) : "NA"}
                                                </StyledTableCell>
                                            <StyledTableCell className='tableCell'>
                                            {user.certificate_url_2 !== null ? (
                                                    <Link
                                                        component={RouterLink}
                                                        to={user.certificate_url_2}
                                                        variant="contained"
                                                        target="_blank"
                                                        style={{backgroundColor: '#1b0080', color: 'white', padding: '8px', textDecorationColor: 'white'}}
                                                    >
                                                        Download Certificate
                                                    </Link>
                                                ) : "NA"}
                                            </StyledTableCell>
                                            <StyledTableCell className='tableCell'>{user.studentData.class}</StyledTableCell>
                                            {inchargeUser && (
                                                <>
                                                    {inchargeUser.isfullPaid === null ? (
                                                        <StyledTableCell className='tableCell' align="center">{inchargeUser.total_amount}</StyledTableCell>
                                                    ) : (
                                                        <StyledTableCell className='tableCell' align="center">Paid</StyledTableCell>
                                                    )}
                                                    <StyledTableCell className='tableCell'>
                                                        <IconButton onClick={() => handleDelete(inchargeUser.id, inchargeUser)}>
                                                            <DeleteIcon style={{color: 'red'}}/>
                                                        </IconButton>
                                                        
                                                    </StyledTableCell>
                                                </>
                                            )}
                                            
                                            </StyledTableRow>
                                        </>  
                                        
                                    );
                                } else {
                                    return null; 
                                }
                            })}
                             <StyledTableRow>
                                <StyledTableCell className='tableCell' colSpan={6}></StyledTableCell>
                                <StyledTableCell className='tableCell'><strong>Total:</strong>{totalAmount}</StyledTableCell>
                                <StyledTableCell className='tableCell'>
                                    {/* <Button
                                        component={RouterLink}
                                        variant="contained"
                                        color="primary"
                                        onClick={handlePayment}
                                        style={{backgroundColor: '#ac2cc4'}}
                                    >
                                        Pay Now
                                    </Button> */}
                                    <div>
                                        {totalAmount === 0 ? (
                                            'paid'
                                        ) : (
                                            <Button
                                            component={RouterLink}
                                            to="/payment"
                                            variant="contained"
                                            color="primary"
                                            onClick={handlePayment}
                                            style={{ backgroundColor: '#ac2cc4' }}
                                            >
                                            Pay Now
                                            </Button>
                                        )}
                                    </div>
                                </StyledTableCell>
                            </StyledTableRow> 
                        </TableBody>
                                       
                    </Table>
                    </TableContainer>
                    {deleteMessage && <Typography variant="body1" color="error">{deleteMessage}</Typography>}
                    </>
                )}
            </div>
        <Footer />    
        </div>
   
    );
}
