import React, { useState, useEffect } from 'react';
import axios from '../../../../axiosApi';
import { TextField, MenuItem, Box, Tab, Tabs, Button, Stepper, Step, StepLabel } from '@mui/material';
import { useParams } from 'react-router-dom';
import ParticipatesDataTable from './ParticipatesDataTable';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TopperTable from './topperTables/topperTable';
import ManualRegister from './manualRegister';
import CuttOffMarks from './cuttOffMarks';


const ParticipatesData = ({ onSubmit }) => {
    const { id } = useParams();
    const [hallticketMessage, setHallticketMessage] = useState("");
    const [certificateMessage, setCertificateMessage] = useState("");
    const [rankCertificateMessage, setRankCertificateMessage] = useState("");
    const [uploadCsvMessage, setUploadCsvMessage] = useState("");
    const [selectedLevel, setSelectedLevel] = useState(1);
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [selectedOption, setSelectedOption] = useState("Participates Data");
    const [parsedData, setParsedData] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [isCompletedHallticket, setIsCompletedHallticket] = useState(false);
    const [isCompletedCertificate, setIsCompletedCertificate] = useState(false);
    const [isCompletedRankCertificate, setIsCompletedRankCertificate] = useState(false);
    const steps = ['Get CSV', 'Select File', 'Upload CSV'];
    const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
    const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);


    useEffect(() => {
      
        setHallticketMessage("");
        setCertificateMessage("");
        setRankCertificateMessage("");
        setUploadCsvMessage("");
        setIsCompletedHallticket(false);
        setIsCompletedCertificate(false);
        setIsCompletedRankCertificate(false);
        setParsedData(null);
        setActiveStep(0);
    }, [selectedLevel]);

    const apiRequest = async (endpoint, setMessage, setCompleted) => {
        try {
            const token = localStorage.getItem('matrix_token');
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };
            const response = await axios.get(`${backendURL}/${endpoint}`, { headers });
            setMessage(response.data.message);
            setCompleted(true);
        } catch (error) {
            setMessage(`Error generating ${endpoint}`);
        }
    };

    const handleGenerateCertificate = () => apiRequest(`bulkcertificates/${id}/${selectedLevel}`, setCertificateMessage, setIsCompletedCertificate);
    const handleGenerateRankCertificate = () => apiRequest(`rankbulkcertificates/${id}/${selectedLevel}`, setRankCertificateMessage, setIsCompletedRankCertificate);
    const handleGenerateHallticket = () => apiRequest(`bulkhallticket/${id}/${selectedLevel}`, setHallticketMessage, setIsCompletedHallticket);

    const handleGetCsv = async () => {
        try {
            const token = localStorage.getItem('matrix_token');
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };
            const response = await axios.get(`${backendURL}/getuploadmarkscsv/${id}/${selectedLevel}`, { headers });
            const headersRow = ['Participate_id', 'Student_Name', 'Aadhar_Number', 'Class', 'Hall_Ticket_Number', 'Total_Marks', 'Obtain_Marks', 'Level_1_Subject1_Id', 'Level_1_Subject1_Name', 'Level_1_Subject1_Total_Marks', 'Level_1_Subject1_Obtain_Marks', 'Level_1_Subject2_Id', 'Level_1_Subject2_Name', 'Level_1_Subject2_Total_Marks', 'Level_1_Subject2_Obtain_Marks'];

            const apiData = response.data.data.map((item) => {
                const subjects = item.participant_subject.map(subject => `${subject.subject_id}, ${subject.subject_name}, ${subject.subject_marks}, ${''}`).join(', ');
                const aadharNumber = String(item.aadhar_number);
                const formattedAadharNumber = aadharNumber.slice(0, 4) + '-' + aadharNumber.slice(4, 8) + '-' + aadharNumber.slice(8);
                return {
                    participate_id: item.participate_id,
                    student_Name: item.participant_user.name,
                    aadhar_number: formattedAadharNumber,
                    class: item.class,
                    hall_ticket_no: item.hall_ticket_no,
                    total_marks: item.total_marks,
                    obtain_marks: item.obtain_marks,
                    subjects: subjects
                };
            });

            const csvData = [headersRow, ...apiData.map(row => Object.values(row))].map(row => row.join(',')).join('\n');
            const blob = new Blob([csvData], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'Participates.csv';
            link.click();
            handleNext();
        } catch (error) {
            console.error('Error generating CSV:', error);
        }
    };

    const removeCircularReferences = (obj) => {
        const seen = new WeakSet();
        function replacer(key, value) {
            if (typeof value === 'object' && value !== null) {
                if (seen.has(value)) {
                    return; // Skip circular references
                }
                seen.add(value);
            }
            return value;
        }
        return JSON.parse(JSON.stringify(obj, replacer));
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        const fileReader = new FileReader();
        fileReader.onload = (event) => {
            try {
                const csvContent = event.target.result;
                const rows = csvContent.split('\n');
                const data = [];
                const headers = rows[0].split(',');

                for (let i = 1; i < rows.length; i++) {
                    const row = rows[i].trim();
                    if (row) {
                        const values = row.split(',');
                        const rowData = {};
                        for (let j = 0; j < headers.length; j++) {
                            const key = headers[j].trim().replace(/"/g, '');
                            const value = values[j] ? values[j].trim() : null;
                            rowData[key] = value;
                        }
                        data.push(rowData);
                    }
                }

                const updatedData = data.map((item) => ({
                    participate_id: item.Participate_id,
                    hall_ticket_no: item.Hall_Ticket_Number,
                    obtain_marks: item.Obtain_Marks,
                    subject_marks: [
                        item.Level_1_Subject1_Id ? { [`${item.Level_1_Subject1_Id.trim()}`]: parseInt(item.Level_1_Subject1_Obtain_Marks) } : null,
                        item.Level_1_Subject2_Id ? { [`${item.Level_1_Subject2_Id.trim()}`]: parseInt(item.Level_1_Subject2_Obtain_Marks) } : null,
                        item.Level_2_Subject1_Id ? { [`${item.Level_2_Subject1_Id.trim()}`]: parseInt(item.Level_2_Subject1_Obtain_Marks) } : null,
                        item.Level_2_Subject2_Id ? { [`${item.Level_2_Subject2_Id.trim()}`]: parseInt(item.Level_2_Subject2_Obtain_Marks) } : null
                    ].filter(Boolean),
                }));

                setParsedData(removeCircularReferences(updatedData));
                handleNext();
            } catch (error) {
                console.error('Error parsing CSV file:', error);
            }
        };
        fileReader.readAsText(file);
    };

    const handleUploadCsv = async () => {
        try {
            const token = localStorage.getItem('matrix_token');

            const response = await axios.post(`${backendURL}/admin/olympiad/${id}/postuploadmarkscsv`, parsedData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            setUploadCsvMessage(response.data.message);
            handleNext();
        } catch (error) {
            console.error('Error uploading data:', error);
            setUploadCsvMessage('Error uploading data');
        }
    };

    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

    const renderCsvStepContent = () => (
        <div style={{marginTop: '6rem'}}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                {activeStep === 0 && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleGetCsv}
                        sx={{ backgroundColor: '#ac2cc4' }}
                    >
                        Get CSV
                    </Button>
                )}
                {activeStep === 1 && (
                    <Button
                        variant="contained"
                        component="label"
                        sx={{ backgroundColor: '#ac2cc4' }}
                    >
                        Select File
                        <input type="file" accept=".csv" onChange={handleFileChange} hidden />
                    </Button>
                )}
                {activeStep === 2 && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleUploadCsv}
                        sx={{ backgroundColor: '#ac2cc4' }}
                    >
                        Upload CSV
                    </Button>
                )}
                {activeStep > 0 && (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleBack}
                        sx={{ backgroundColor: '#ac2cc4' }}
                    >
                        Back
                    </Button>
                )}
            </Box>
            {uploadCsvMessage && <p style={{ color: 'red', display: 'flex', justifyContent: 'center' }}>{uploadCsvMessage}</p>}
        </div>
    );

    const renderGenerateButton = (isCompleted, onClick, buttonText, message) => (
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '2rem', flexDirection: 'column' }}>
            <CheckCircleIcon
                sx={{
                    width: '3rem',
                    height: '3rem',
                    marginBottom: '1rem',
                    color: isCompleted ? 'green' : 'gray',
                    marginTop: '6rem'
                }}
            />
            <Button
                variant="contained"
                color="primary"
                disabled={isCompleted}
                onClick={onClick}
                sx={{ backgroundColor: '#ac2cc4', width: 'auto' }}
            >
                {buttonText}
            </Button>
            {message && <p style={{ color: 'red', display: 'flex', justifyContent: 'center' }}>{message}</p>}
        </Box>
    );


    return (
        <>
            <Box sx={{ width: '30%' }}>
                <TextField
                    required
                    select
                    name='Level'
                    label='Level'
                    value={selectedLevel}
                    onChange={(event) => setSelectedLevel(event.target.value)}
                    sx={{ width: '15rem', margin: '2rem 0' }}
                >
                    {[1, 2].map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                <Box sx={{ marginRight: '2rem', minWidth: '200px', position: 'sticky', left: '0rem', marginTop: '5rem'}}>
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={selectedOption}
                        onChange={(event, newValue) => handleOptionChange(newValue)}
                        aria-label="Vertical tabs example"
                    >
                       {["Participates Data", `Level${selectedLevel} Manual Register`, "Generate Hall Ticket", selectedLevel === 1 ? "Cutoff Marks" : null, "CSV", "Generate Certificate", "Topper List"].map((option) => (
                            option && <Tab key={option} label={option} value={option} />
                        ))}
                    </Tabs>
                </Box>
                <Box sx={{ flex: 1 }}>
                    {selectedOption === "Participates Data" && (
                        <ParticipatesDataTable selectedLevel={selectedLevel} key={selectedLevel} />
                    )}
                    {selectedOption === "CSV" && renderCsvStepContent()}
                    {selectedOption === "Generate Hall Ticket" && renderGenerateButton(isCompletedHallticket, handleGenerateHallticket, "Generate Hall Ticket", hallticketMessage)}
                    {selectedOption === "Generate Certificate" && (
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '5rem'}}>
                            {renderGenerateButton(
                            isCompletedCertificate,
                            handleGenerateCertificate,
                            "Generate Certificate",
                            certificateMessage
                            )}
                            {renderGenerateButton(
                            isCompletedRankCertificate,
                            handleGenerateRankCertificate,
                            "Generate Rank Certificate",
                            rankCertificateMessage
                            )}
                        </div>
                    )}
                    {selectedOption === `Level${selectedLevel} Manual Register` && <ManualRegister  selectedLevel={selectedLevel} id={id} />}
                    {selectedOption === "Cutoff Marks" && <CuttOffMarks  id={id}/>}
                    {selectedOption === "Topper List" && <TopperTable selectedLevel={selectedLevel} id={id} />}

                </Box>
            </Box>

        </>
    );
};

export default ParticipatesData;
