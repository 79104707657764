import React from 'react'
import Sidenav from './sidenav/sidenav'
import Profile from '../../profile'
import Footer from '../../Footer/footer';

export default function adminProfile() {
  return (
    <>
        <Sidenav />
        <Profile />
        <Footer />

    </>
  )
}
