import React, { useState, useEffect } from 'react';
import axios from '../../../../axiosApi';
import { useParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { Table, TableBody, TableCell, TableHead, TableRow, Container, Box, Link } from '@mui/material';
import Sidenav from '../sidenav/sidenav';
import Footer from '../../../Footer/footer';

const SingleParticipate = () => {
    const { olympiadId, userId } = useParams();
    const [participateData, setParticipateData] = useState(null);
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    
    useEffect(() => {
        const fetchParticipateData = async () => {
            try {
                const token = localStorage.getItem('matrix_token'); 
                const headers = {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                };
                const response = await axios.get(`${backendURL}/admin/olympiad/${olympiadId}/allparticipate/user/${userId}`, { headers });
                setParticipateData(response.data.data);

            } catch (error) {
                console.error('Error fetching participate data:', error);
            }
        };

        fetchParticipateData();
    }, [olympiadId, userId, backendURL]);

    // Render logic for participateData...

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column'}}>
        <Sidenav />
        <Box sx={{ display: 'flex', marginLeft: '5rem' }}>
            <Container sx={{ flexGrow: 1, justifyContent: 'center', marginTop: '2rem'}} maxWidth='md'>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 'auto' }}>
                    {Array.isArray(participateData) && participateData.map((student, index) => {
                        return (
                            <Table style={{ width: '45%', border: '1px solid #ccc' }}>
                            <TableHead  key={student.participant_user.id}>
                                <TableRow>
                                <TableCell colSpan={2} style={{ textAlign: 'center' }}>Student Details</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                <TableCell className='tableCell'><strong>Id</strong></TableCell>
                                <TableCell>{student.participant_user.id}</TableCell>
                                </TableRow>
                                <TableRow>
                                <TableCell className='tableCell'><strong>Name</strong></TableCell>
                                <TableCell>{student.participant_user.name}</TableCell>
                                </TableRow>
                                <TableRow>
                                <TableCell className='tableCell'><strong>Email</strong></TableCell>
                                <TableCell>{student.participant_user.email}</TableCell>
                                </TableRow>
                                <TableRow>
                                <TableCell className='tableCell'><strong>Phone</strong></TableCell>
                                <TableCell>{student.participant_user.phone}</TableCell>
                                </TableRow>
                                <TableRow>
                                <TableCell className='tableCell'><strong>Aadhar Number</strong></TableCell>
                                <TableCell>{student.participant_user.aadhar_number}</TableCell>
                                </TableRow>
                                <TableRow>
                                <TableCell className='tableCell'><strong>Class</strong></TableCell>
                                <TableCell>{student.participant_user.class}</TableCell>
                                </TableRow>
                                <TableRow>
                                <TableCell className='tableCell'><strong>Hall Ticket Number</strong></TableCell>
                                <TableCell>{student.hall_ticket_no}</TableCell>
                                </TableRow>
                                <TableRow>
                                <TableCell className='tableCell'><strong>Certificate Url1</strong></TableCell>
                                <TableCell>
                                    {student.certificate_url !== null ? (
                                        <Link
                                            component={RouterLink}
                                            to={student.certificate_url}
                                            variant="contained"
                                            target="_blank"
                                            style={{backgroundColor: '#1b0080', color: 'white', padding: '8px', textDecorationColor: 'white'}}
                                        >
                                            Download Certificate
                                        </Link>
                                    ) : "NA"}
                                </TableCell>
                                </TableRow>
                                <TableRow>
                                <TableCell className='tableCell'><strong>Certificate Url2</strong></TableCell>
                                <TableCell>
                                    {student.certificate_url_2 !== null ? (
                                        <Link
                                            component={RouterLink}
                                            to={student.certificate_url_2}
                                            variant="contained"
                                            target="_blank"
                                            style={{backgroundColor: '#1b0080', color: 'white', padding: '8px', textDecorationColor: 'white'}}
                                        >
                                            Download Certificate
                                        </Link>
                                    ) : "NA"}
                                </TableCell>
                                </TableRow>
                                <TableRow>
                                <TableCell className='tableCell'><strong>Total Amount</strong></TableCell>
                                <TableCell>{student.total_amount}</TableCell>
                                </TableRow>
                                <TableRow>
                                <TableCell className='tableCell'><strong>Total Marks</strong></TableCell>
                                <TableCell>{student.total_marks}</TableCell>
                                </TableRow> 
                                <TableRow>
                                <TableCell className='tableCell'><strong>Obtain Marks</strong></TableCell>
                                <TableCell>{student.obtain_marks}</TableCell>
                                </TableRow>
                        </TableBody>
                        </Table>
                        );
                        
                    })}
                </Box>
            </Container>
        </Box>
        <Footer />
    </Box>
    );
};

export default SingleParticipate;
