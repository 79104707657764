import React, { useState } from 'react';
import { Button, CssBaseline, TextField, Link, Grid, Box, Container, Typography, InputAdornment, IconButton } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Navigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios from '../axiosApi';
import NavBar from '../AuthenticateLayout/Dashboard/UsersDashboard/NavBar/navBar';
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';
import LoginAvatar from '../../../assets/loginAvatar.jpg'
import Footer from '../AuthenticateLayout/Footer/footer';

const defaultTheme = createTheme();

export default function LoginPage() {
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const initialFormData = {
    email: '',
    password: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [redirectToReferrer, setRedirectToReferrer] = useState(null);
  const [loginError, setLoginError] = useState('');
  const [requiredFieldsError, setRequiredFieldsError] = useState({
    email: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    setRequiredFieldsError({
      ...requiredFieldsError,
      [name]: '',
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const requiredFields = ['email', 'password'];
    const newRequiredFieldsError = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    requiredFields.forEach(field => {
      if (!formData[field]) {
        newRequiredFieldsError[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required.`;
      }
    });

    if (formData['email'] && !emailRegex.test(formData['email'])) {
      newRequiredFieldsError['email'] = 'Invalid email format.';
    }

    if (Object.keys(newRequiredFieldsError).length > 0) {
      setRequiredFieldsError(newRequiredFieldsError);
      setLoading(false); 
      return;
    }
    try {
      const headers = {
        'Content-Type': 'application/json'
      };
      const response = await axios.post(`${backendURL}/login`, formData, { headers });
      
      const matrix_user = {
        id: response.data.user.id,
        name: response.data.user.name,
        email: response.data.user.email,
        role: response.data.user.role,
      };
      localStorage.setItem('matrix_user', JSON.stringify(matrix_user));
      localStorage.setItem('matrix_token', response.data.token);

      if (matrix_user.role === 1) {
        setRedirectToReferrer("/admin/dashboard");
      } else if (matrix_user.role === 2) {
        setRedirectToReferrer("/");
      } else if (matrix_user.role === 5) {
        setRedirectToReferrer("/");
      } else if (matrix_user.role === 6) {
        alert("You are not Approved, Wait for Admin Approval");
      } else {
        setRedirectToReferrer("/404");
      }
      
    } catch (error) {
      console.error('Login failed:', error);
      if (error.response && error.response.data && error.response.data.error) {
        setLoginError(error.response.data.error);
      } else {
        setLoginError('Login failed. Please try again.');
      }
    } finally {
      setLoading(false); 
    }
  };

  if (redirectToReferrer) {
    return <Navigate to={redirectToReferrer} />;
  }
  
  return (
    <>
      <NavBar />
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="md">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 28,
              marginBottom: 24,
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'space-between',
              backgroundColor: 'white',
              boxShadow: '0 3px 15px 0 rgba(0, 0, 0, .16)',
              padding: '1.5rem',
              borderRadius: '25px'
            }}
          >
            <Box sx={{ width: { xs: '100%', md: '48%' }, mb: { xs: 3, md: 0 } }}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                <AccountCircleSharpIcon style={{ fontSize: '2rem', color: '#ac2cc4' }} />
                <Typography component="h1" variant="h4">
                  Login
                </Typography>
              </div>
              <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  {Object.entries(formData).map(([key, value]) => (
                    <Grid item xs={12} key={key}>
                      <TextField
                        required
                        fullWidth
                        name={key}
                        label={key.charAt(0).toUpperCase() + key.slice(1)}
                        type={
                          key === 'password'
                            ? showPassword
                              ? 'text'
                              : 'password'
                            : key === 'email'
                            ? 'email'
                            : 'text'
                        }
                        value={value}
                        onChange={handleChange}
                        InputLabelProps={{
                          shrink: {value} ? true : false,
                        }}
                        InputProps={
                          key === 'password'
                            ? {
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      edge="end"
                                    >
                                      {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }
                            : null
                        }
                      />
                      {requiredFieldsError[key] && <Typography variant="body2" color="error">{requiredFieldsError[key]}</Typography>}
                    </Grid>
                  ))}
                </Grid>
                {loginError && <Typography variant="body2" color="error">{loginError}</Typography>}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={handleSubmit}
                  disabled={loading}
                  style={{ backgroundColor: '#ac2cc4' }}
                >
                  {loading ? 'Loading...' : 'Login'}
                </Button>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Link
                      component={RouterLink}
                      variant="contained"
                      color="primary"
                      to='/register'
                    >
                      Don't have an account? Sign Up
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box sx={{ width: { xs: '100%', md: '48%' }, textAlign: 'center' }}>
              <img src={LoginAvatar} alt="login-avatar" style={{ width: '100%', height: 'auto' }} />
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      <Footer />
    </>
  );
}
