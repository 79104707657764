import React from 'react';
import '../../../../index.css'

export default function Footer() {
    const wordPressURL = process.env.REACT_APP_WORDPRESS_URL;
  return (
    <div className='footer-section'>
        <hr className='line-break'/>
        <div className='footer'>
            <div className='policy-section'>
                {/* <span className='line'></span>
                <a className='policy' href={`${wordPressURL}/privacy-policy`}>Privacy Police</a>
                <span className='line'></span>
                <a className='policy' href={`${wordPressURL}/contact`}>Contact Details</a>
                <span className='line'></span> */}
                <div>
                    <ul className='footer-list'>
                        <li><a className='policy' href={`${wordPressURL}/contact`}>Contact Details</a></li>
                        <li><a className='policy' href={`${wordPressURL}/privacy-policy`}>Privacy Police</a></li>
                        <li><a className='policy' href={`${wordPressURL}/lp-term-conditions`}>Term & Conditions</a></li>
                        <li><a className='policy' href={`${wordPressURL}/refund_returns`}>Return & Refund</a></li>
                    </ul>
                </div>
                <div>
                    <ul className='footer-list'>
                        <li><a className='policy' href='/login'>Login</a></li>
                        <li><a className='policy' href='/student/register'>Student Register</a></li>
                        <li><a className='policy' href='/incharge/register'>Staff Register</a></li>
                    </ul>
                </div>
            </div>
            <div className='pdw'>
                <p className='copyrights'>© 2024 Matrix Olympiads Exam.All Rights Reserved.</p>
                <p className='pdw-text'>Design and Developed By <a className='pdw-title' href="https://phantasm.in/">Phantasm</a></p>
            </div>
        </div>
    </div>
    
  )
}
