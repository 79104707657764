import React, { useState, useEffect } from 'react';
import axios from '../../../../axiosApi';
import { TableContainer, Table, TableHead, TableBody, TableCell, TableRow, Paper, Link, Pagination, Box, tableCellClasses } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import Diversity3Icon from '@mui/icons-material/Diversity3';

export default function ParticipatesDataTable({ selectedLevel }) {
    const [participatesData, setParticipatesData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pagination, setPagination] = useState(null);
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [message, setMessage] = useState("");

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const { id } = useParams();

    const fetchParticipatesData = async (page) => {
        try {
            const token = localStorage.getItem('matrix_token');
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };
            const response = await axios.get(`${backendURL}/allparticipate/${id}?page=${page}`, { headers });
            setParticipatesData(response.data.data.data);
            setPagination(response.data.data);
        } catch (error) {
            console.error('Error fetching participates data:', error);
            setMessage('Error fetching participates data');
        }
    };

    useEffect(() => {
        fetchParticipatesData(currentPage);
    }, [id, backendURL, currentPage]);

    const participateUserData = participatesData.map(user => ({
        studentData: user.participant_user,
        hall_ticket_no: user.hall_ticket_no,
        total_amount: user.total_amount,
        total_marks: user.total_marks,
        obtain_marks: user.obtain_marks,
        olympiad_id: user.olympiad_id,
        level: user.level
    }));

    const participateUserLevel1Data = participateUserData.filter(user => user.level === 1);
    const participateUserLevel2Data = participateUserData.filter(user => user.level === 2);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const displayData = selectedLevel === 1 ? participateUserLevel1Data : participateUserLevel2Data;

    return (
        <Box>
            <div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                    <Diversity3Icon />
                    <h3>All Participates Data</h3>
                </div>
                {message && <p style={{ color: 'red' }}>{message}</p>}
                <TableContainer component={Paper} sx={{ maxHeight: { xs: 'auto', md: 600 }, boxShadow: '0 3px 15px 0 rgba(0, 0, 0, .16)' }}>
                    <Table stickyHeader style={{ border: '1px solid #ccc', boxShadow: '0 3px 15px 0 rgba(0, 0, 0, .16)' }}>
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell className='tableCell'>Student Name</StyledTableCell>
                                <StyledTableCell className='tableCell'>Student Email</StyledTableCell>
                                <StyledTableCell className='tableCell'>Student Aadhar Number</StyledTableCell>
                                <StyledTableCell className='tableCell'>Student Class</StyledTableCell>
                                <StyledTableCell className='tableCell'>Hall Ticket Number</StyledTableCell>
                                <StyledTableCell className='tableCell'>Total Amount</StyledTableCell>
                                <StyledTableCell className='tableCell'>Total marks</StyledTableCell>
                                <StyledTableCell className='tableCell'>Obtained marks</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {Array.isArray(displayData) && displayData.map((student, index) => (
                                <StyledTableRow key={student.studentData.aadhar_number}>
                                    <StyledTableCell className='tableCell'>
                                        <Link
                                            to={`/admin/olympiad/${student.olympiad_id}/allparticipate/user/${student.studentData.id}`}
                                            component={RouterLink}
                                            variant="contained"
                                            color="primary"
                                        >
                                            {student.studentData.name}
                                        </Link>
                                    </StyledTableCell>
                                    <StyledTableCell className='tableCell'>{student.studentData.email}</StyledTableCell>
                                    <StyledTableCell className='tableCell'>{student.studentData.aadhar_number}</StyledTableCell>
                                    <StyledTableCell className='tableCell'>{student.studentData.class}</StyledTableCell>
                                    <StyledTableCell className='tableCell'>{student.hall_ticket_no}</StyledTableCell>
                                    <StyledTableCell className='tableCell'>{student.total_amount}</StyledTableCell>
                                    <StyledTableCell className='tableCell'>{student.total_marks}</StyledTableCell>
                                    <StyledTableCell className='tableCell'>{student.obtain_marks}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {pagination && (
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                        <Pagination
                            count={pagination.last_page}
                            page={currentPage}
                            onChange={handlePageChange}
                            variant="outlined"
                            shape="rounded"
                        />
                    </div>
                )}
            </div>
        </Box>
    )
}
