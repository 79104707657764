import React from 'react'
import OlympiadId from '../../Olympiads/olympiadId';
import NavBar from './NavBar/navBar';
import Footer from '../../Footer/footer';

export default function UserOlympiadId() {
  return (
    <>
        <NavBar />
        <OlympiadId />
        <Footer />
    </>
    
  )
}
