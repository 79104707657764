import React, { useState, useEffect } from 'react';
import { TextField, MenuItem, Box, Tab, Tabs } from '@mui/material';
import TopperListTable from './allToppers'
import JuniorTopperListTable from './juniorToppers';
import SeniorTopperListTable from './seniorToppers';
import axios from '../../../../../axiosApi';

export default function TopperTable( { selectedLevel, id } ) {

    const [selectedClass, setSelectedClass] = useState(8);
    const [selectedSchool, setSelectedSchool] = useState(3);
    const [selectedTab, setSelectedTab] = useState(0);
    const [options, setOptions] = useState([]);
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(`${backendURL}/school`);
            setOptions(response.data);
          } catch (error) {
            if (error.response && error.response.status === 429) {
              const delay = Math.pow(2, 3) * 1000; 
              await new Promise(resolve => setTimeout(resolve, delay));
              fetchData(); 
            }
          }
        };
      
        fetchData(); 
      }, [backendURL]);

     

  return (
    <>
            <Box sx={{  width: 'auto'}}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                        <h3>Topper Lists</h3>
                    </Box>
                    <Tabs value={selectedTab} onChange={handleTabChange} aria-label="Topper Lists" style={{margin: 'auto'}}>
                        <Tab label="Topper List" />
                        <Tab label="Senior Topper List" />
                        <Tab label="Junior Topper List" />
                    </Tabs>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                        <Box sx={{ width: '100%' }}>
                        <TextField
                            required
                            select
                            name='Class'
                            label='Class'
                            value={selectedClass}
                            onChange={(event) => setSelectedClass(event.target.value)}
                            sx={{ width: '15rem', margin: '2rem 0' }}
                        >
                            {[3,4,5,6,7,8,9,10].map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                        </Box>
                        <Box sx={{ width: '35%' }}>
                            <TextField
                                required
                                select
                                name='School_id'
                                label='School_id'
                                value={selectedSchool}
                                onChange={(event) => setSelectedSchool(event.target.value)}
                                sx={{ width: '15rem', margin: '2rem 0' }}
                            >
                                {options.map(option => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.school_name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    </div>
                
                {selectedTab === 0 && <TopperListTable selectedClass={selectedClass} selectedLevel={selectedLevel} id={id} />}
                {selectedTab === 1 && <SeniorTopperListTable  selectedClass={selectedClass} selectedLevel={selectedLevel} selectedSchool={selectedSchool} id={id} />}
                {selectedTab === 2 && <JuniorTopperListTable  selectedClass={selectedClass} selectedLevel={selectedLevel} selectedSchool={selectedSchool} id={id} />}
            </Box>
        </>
  )
}
