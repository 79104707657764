import React from 'react';

import TimeLine from './timeLine';
import NavBar from '../AuthenticateLayout/Dashboard/UsersDashboard/NavBar/navBar';
import Olympiads from '../AuthenticateLayout/Olympiads/olympiads'
import Footer from '../AuthenticateLayout/Footer/footer';

const WelcomePage = () => {

    return (
        <div>
                <NavBar />
            
                <Olympiads />
                <TimeLine />
                <Footer />
            

        </div>
       
    );
};

export default WelcomePage;
