import React, { useState, useEffect } from 'react';
import axios from '../../../../../axiosApi';
import { Table, TableBody, TableCell, TableHead, TableRow, Container, Box, Button, styled, tableCellClasses } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import Sidenav from '../../sidenav/sidenav'; 
import Footer from '../../../../Footer/footer';

export default function SingleInchargeData() {
    const [singleInchargeData, setSingleInchargeData] = useState([]);
    const [singleInchargeSchool, setSingleInchargeSchool] = useState([]);
    const [redirectToReferrer, setRedirectToReferrer] = useState(false);
    const { id } = useParams(); 
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

    useEffect(() => {
        const fetchSingleInchargeData = async () => {
            try {
                const token = localStorage.getItem('matrix_token'); 
                const headers = {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                };
                const response = await axios.get(`${backendURL}/admin/incharge/${id}`, { headers });
                setSingleInchargeData(response.data.data);
                setSingleInchargeSchool(response.data.data.user_school);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchSingleInchargeData();
    }, [id, backendURL]);

    const handleApprove = async () => {
        try {
            const token = localStorage.getItem('matrix_token');
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };
            
            const response = await axios.post(`${backendURL}/admin/approve/${id}`, { id }, { headers });
            setRedirectToReferrer(true);
        } catch (error) {
            console.error('Error approving:', error);
        }
    };
    if (redirectToReferrer) {
        return <Navigate to="/admin/incharges" />;
      }

    const handleUnApprove = async () => {
        try {
            const token = localStorage.getItem('matrix_token');
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };
            
            const response = await axios.post(`${backendURL}/admin/incharge/${id}/unapprove/`, { id }, { headers });
        } catch (error) {
            console.error('Error unapproving:', error);
        }
    };

  return (
    <>
    <Sidenav />
    <Box sx={{ display: 'flex' }}>
        
        <Container sx={{ flexGrow: 1, justifyContent: 'center'}}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center',  marginTop: '4rem' }}>
                {singleInchargeData && (
                    <Table  style={{ width: '45%', border: '1px solid #ccc', boxShadow: '0 3px 15px 0 rgba(0, 0, 0, .16)' }}>
                    <TableHead>
                        <StyledTableRow>
                        <StyledTableCell colSpan={2} style={{ textAlign: 'center' }}>Incharge Details</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow>
                        <StyledTableCell className='tableCell'><strong>Id</strong></StyledTableCell>
                        <StyledTableCell>{singleInchargeData.id}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                        <StyledTableCell className='tableCell'><strong>Name</strong></StyledTableCell>
                        <StyledTableCell>{singleInchargeData.name}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                        <StyledTableCell className='tableCell'><strong>Email</strong></StyledTableCell>
                        <StyledTableCell>{singleInchargeData.email}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                        <StyledTableCell className='tableCell'><strong>Phone</strong></StyledTableCell>
                        <StyledTableCell>{singleInchargeData.phone}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                        <StyledTableCell className='tableCell'><strong>Aadhar Number</strong></StyledTableCell>
                        <StyledTableCell>{singleInchargeData.aadhar_number}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                        <StyledTableCell className='tableCell'><strong>School Name</strong></StyledTableCell>
                        <StyledTableCell>{singleInchargeSchool.school_name}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                        <StyledTableCell className='tableCell'><strong>School Email</strong></StyledTableCell>
                        <StyledTableCell>{singleInchargeSchool.school_email}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                        <StyledTableCell className='tableCell'><strong>School Phone</strong></StyledTableCell>
                        <StyledTableCell>{singleInchargeSchool.school_phone}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                        <StyledTableCell className='tableCell'><strong>School Unique Code</strong></StyledTableCell>
                        <StyledTableCell>{singleInchargeSchool.school_unique_code}</StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                    </Table>
                )}
                {singleInchargeData.role === 6 && (
                    <div>
                        <Button variant="contained" color="primary" onClick={handleApprove} style={{marginTop: '1rem', marginRight: '1rem', backgroundColor: '#ac2cc4'}}>
                            Approve
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleUnApprove} style={{marginTop: '1rem', backgroundColor: '#ac2cc4'}}>
                            Unapprove
                        </Button>
                    </div>
                    
                    
                )}
            </Box>
        </Container>
        
    </Box>
    <Footer />
    </>
  )
}
