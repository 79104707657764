import { React, useState } from 'react';
import { Container, Box } from '@mui/material';
import Sidenav from '../sidenav/sidenav';
import OlympiadId from '../../../Olympiads/olympiadId';
import ParticipatesData from './ParticipatesData';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Footer from '../../../Footer/footer';

const SingleOlympiad = () => {
    const [showParticipates, setShowParticipates] = useState(false);

    const toggleParticipates = () => {
        setShowParticipates(!showParticipates);
    };

    return (
        <>
        <Sidenav />
        <Box >
            <Container style={{display: 'flex' , justifyContent: 'center'}} maxWidth="md">
                <Box sx={{ marginLeft: '12rem', marginTop: '1rem'}}>
                    <FormControlLabel
                        sx={{
                        display: 'block',
                       
                        }}
                        control={
                        <Switch
                            checked={showParticipates}
                            onClick={toggleParticipates}
                            name="loading"
                            style={{color: '#ac2cc4', backgroundColor: '#ac2cc4 !importtant'}}
                        />
                        }
                        label="Show Olympiad Details"
                    />
                    {showParticipates ? (
                            <OlympiadId />
                            
                        ) : (
                            <>
                              <ParticipatesData />  
                            </>
                        )} 
                </Box>
                
            </Container>   
        </Box>
        <Footer />
        </>
    );
};

export default SingleOlympiad;
